<template>
    <div class="recommended_container" v-if="showAd===true">
    <div class="recommended">
        <img src="/img/warning.png" alt="">
        <p>Recomanem l'ús de Google Chrome en Android o Safari en iOS per a una millor experiència</p>
        <button @click="copyToClipboard">Copiar link</button>
        <button @click="showAd=false">Tancar</button>
    </div>
    </div>

    <div class="main_view" :style="'background-image:url('+ Bg+')'">
        <h1 class="title">Dia del comerç local</h1>
        <h2 class="subtitle">Gymkana virtual</h2>
        <img :src="MainScreenImg" alt="">
        <div class="button_container">
        <button v-if="mainStore.isLoggedIn === false" @click="this.$router.push('login')" >Inici de sessió</button>
        <button v-if="mainStore.isLoggedIn === false" @click="this.$router.push('register')">Registre</button>
        <button v-if="mainStore.isLoggedIn === true"  @click="startAll()">Començar</button>
        </div>
    </div>
</template>
<script>

import { useMainStore } from '@/stores/mainStore';
import Bg from '@/assets/bg.png';
import MainScreenImg from '@/assets/main_screen_img.svg';
import PremisModal from '@/components/PremisModal.vue';


export default{
    name: "MainView",
    setup() {
        const mainStore = useMainStore();
        return { mainStore };
    },
    data: function () {
        return {
            Bg: Bg,
            MainScreenImg: MainScreenImg,
            showAd: true
        };
    },
    methods: {
        startAll: function () {
            this.$router.push("map");
            this.mainStore.experienceStarted = true;
        },
        copyToClipboard: function(){
            navigator.clipboard.writeText('https://ideat-ciudadanos.nowarlab.com');
            alert('Link copiat!')
        },
    },
    components: { PremisModal }
}
</script>

<style scoped>

.recommended{
    background-color: rgba(255,255,255,0.9);
    border-radius: 12px;
    width: 256px;
    padding:20px;
    display: flex;
    flex-direction: column;
    gap:5px;
    align-items: center;
    font-family: 'Bree Serif';
}
.recommended_container{
    width:100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background: rgba(0,0,0,.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.title{
    color:#0300A6;
    font-family: 'Bree Serif';
    font-weight: 500;
}

.subtitle{
    color:#0300A6;
    font-family: 'Bree Serif';
    font-weight: 500;
}
.main_view{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.button_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

button{
    background-color: #90c172;
    border:none;
    outline: none;
    border-radius: 20px;
    width: 70%;
    height:40px;
    color:#fff;
    font-family: 'Bree Serif';
}
</style>