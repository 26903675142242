<template>
    <div class="steps_container" v-show="mainStore.premisTriggered === true">
        <div class="step">
            <h1>Premis</h1>
            <h2>50€</h2>
            <h3>X</h3>
            <p>50 premis al moment amagats amb  el Rasca i Guanya.</p>

            <h2>300€</h2>
            <h3>X</h3>
            <p>3  premis que se sortejaran el 7/11/2022 entre tots aquells que hagin visitat almenys 3 establiments participants.</p>

            <p>Tots els premis seran per comprar a qualsevol dels establiments participants.</p>
            <br>
            <button @click="next()">Entés</button>
        </div>
    </div>
</template>

<script>
import pas_1 from '@/assets/pas_1.svg';
import pas_2 from '@/assets/pas_2.svg';
import pas_3 from '@/assets/pas_3.svg'
import { useMainStore } from '@/stores/mainStore';
export default{
    name:'StepsModal',
    data:function(){
        return{
            pas_1:pas_1,
            pas_2:pas_2,
            pas_3:pas_3
        }
    },
    setup(){
        const mainStore = useMainStore();
        return {mainStore}
    },
    methods:{
        next: function(){
            this.mainStore.premisTriggered = false 
        }
    }
}
</script>

<style>
.steps_container{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 999;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.3);
}

.step{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    height:480px;
    background-color: #90c172;
    border-radius: 20px;
    padding: 10px;
}

.step>h1{
    color:#fff;
}
.step>h2{
    color:#fff;
    font-family: 'Bree Serif';
    font-weight: 400;
}
.step>h3{
    color:#fff;
    font-family: 'Bree Serif';
    font-weight: 400;
}

.step>p{
    font-family: 'Bree Serif';
    font-size:18px;
    color:#fff;
    text-align: center;
}

.step>button{
    background-color: #fff;
    border:none;
    outline:none;
    border-radius:20px;
    color:#90c172;
    font-family: 'Bree Serif';
    font-size:18px;
    padding: 5px;
    width: 70%;
}
</style>