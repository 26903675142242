import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia';
import aframe from 'aframe';
import 'aframe-extras';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBa1fFKZ2trNfBl7HAO6AA7pPDr349Jb5A",
  authDomain: "torrent-d34fc.firebaseapp.com",
  projectId: "torrent-d34fc",
  storageBucket: "torrent-d34fc.appspot.com",
  messagingSenderId: "267657519515",
  appId: "1:267657519515:web:35a3973a770ea2d74f813e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const pinia = createPinia();
const vueApp = createApp(App);

vueApp.use(aframe);
vueApp.use(router);
vueApp.use(pinia);
vueApp.mount('#app')


