<template>
    <div class="modal_container"  v-if="mainStore.errorModal === true"> 
    <div class="modal">
        <p>Encara ets lluny</p>
        <button @click="close()">Continuar</button>
    </div>
</div>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
    export default{
        name: 'ErrorModal',
        setup(){
            const mainStore = useMainStore();
            return {mainStore}
        },
        methods:{
            close: function(){
                this.mainStore.errorModal = false;
            }
        }
    }
</script>

<style scoped>
    .modal{
        width:256px;
        height:192px;
        display:flex;
        flex-direction:column;
        align-items: center;
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-color: #90c172;
        border-radius:12px;
        font-family: 'Bree Serif';
        color:#fff;
        justify-content: space-between;
        padding: 25px;

    }

    .modal>button{
        background-color: #fff;
        border:none;
        outline: none;
        border-radius: 20px;
        width: 70%;
        height:40px;
        color:#fff;
        font-family: 'Bree Serif';
        font-size:18px;
        color:#90c172
    }

    .modal_container{
        position:absolute;
        width: 100%;
        height:100%;
        z-index:999;
        display:flex;
        flex-direction: column;
        justify-content: center;
        background-color: transparent;
        align-items: center; 
    }
</style>