import { defineStore} from "pinia";

export const useMainStore = defineStore('mainStore',{
    state: ()=>{
        return{
        showGame: false,
        currentStep:0,
        stepsTriggered: true,
        experienceStarted : false,
        premisTriggered:false,
        showInstructions:true,
        startGame:false,
        isLoggedIn: false,
        isQuestionTriggered:false,
        questionTitle: null,
        questionOptionA: null,
        questionOptionB:null,
        questionOptionC:null,
        correctQuestion:null,
        currentMap: null,
        clickedMarker:null,
        clickedMarkerCategory: null,
        currentLocation:null,
        gameWon: false,
        givePrize: null,
        errorModal:false,
        userName: null,
        uid: null,
        userLocations: null,
        totalHotspots: 0,
        currentHotspots: 0,
        score:0
    }
    }
})